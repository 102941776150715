import { Fragment } from "react";
import Head from "next/head";
import classNames from "classnames";
import css from "../styles/home.module.css";
import OrderWidget from "../components/OrderWidget";

const Home = () => (
  <Fragment>
    <Head>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-178124317-1"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html:
            "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-178124317-1');",
        }}
      ></script>

      <title>Rantic - Social Media, Marketing & PR Company</title>
      <meta
        name="description"
        content="Best place to cheat buy Instagram followers, Facebook likes, Youtube views & Twitter followers, and many more such as tik tok followers, buy traffic. Cheat your way to the top."
      />
      <meta name="author" content="Rantic" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    </Head>
    <div className={classNames("relative overflow-hidden", css.hero)}>
      <div className={classNames(css.rotatedBackground)}>&nbsp;</div>
      <div className={css.sparklesBackground}>&nbsp;</div>
      <div
        className={classNames(
          css.heroContent,
          "mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        )}
      >
        <div className="flex justify-center mb-6">
          <img src="/logo-light.svg" className={css.logo} />
        </div>
        <h2 className="text-5xl tracking-tight leading-10 font-extrabold text-white sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Become a star on
          <br className=" md:inline" />
          <span className="text-white opacity-50">social media</span>
        </h2>
        <p className="mt-6 text-1xl md:text-2xl bold text-white">
          They fake it before they make it, why can't you?
          <br />
          This is how people get famous.
        </p>
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-screen-xl mx-auto font-extrabold text-white opacity-50 mb-4">
            as seen on
          </div>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5 mb-12">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="/Forbes_logo.svg" alt="Forbes" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="/cnet-icon.svg" alt="cnet" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img
                className="h-12"
                src="/huffington-post.svg"
                alt="Huffington Post"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <img
                className="h-12"
                src="/washington-post.svg"
                alt="Washington Post"
              />
            </div>
            <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
              <img className="h-12" src="/techcrunch.svg" alt="Techcrunch" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              />
            </svg>
            <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="text-4xl leading-8 font-extrabold text-indigo-600 tracking-tight sm:text-4xl sm:leading-9">
                  How does it work?
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-500 mt-6">
                  Rantic is the worlds leading social media marketing company.
                  We are unique in our industry in that we offer marketing
                  solutions that drive quality followers to your accounts
                  through our own reliable technologies.
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Buy Instagram Followers, Facebook Likes, and more today with
                  Rantic.
                </p>
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            viewBox="0 0 140 140"
                            width="140"
                            height="140"
                          >
                            <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                              <path
                                d="M24,2a2,2,0,0,0-2-2H5.5a2,2,0,0,0-2,2V18.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2ZM12.468,8.042a.75.75,0,1,1-.936-1.172L13.22,5.52a1.25,1.25,0,0,1,2.03.977V13a.25.25,0,0,0,.25.25H17a.75.75,0,0,1,0,1.5H12a.75.75,0,0,1,0-1.5h1.5a.25.25,0,0,0,.25-.25V7.016Z"
                                fill="#6875f5"
                                stroke="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                              ></path>
                              <path
                                d="M20.5,23a1,1,0,0,0-1-1H2.5a.5.5,0,0,1-.5-.5V4.5a1,1,0,0,0-2,0V22a2,2,0,0,0,2,2H19.5A1,1,0,0,0,20.5,23Z"
                                fill="#6875f5"
                                stroke="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          Pick a service
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          We have the largest selection of services for the
                          widest variety of social platforms. All you have to do
                          is pick!
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            viewBox="0 0 140 140"
                            width="140"
                            height="140"
                          >
                            <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                              <path
                                d="M22,0H5.5a2,2,0,0,0-2,2V18.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm-5.5,14.75H11a.75.75,0,0,1-.5-1.313l3.594-3.17c.975-.86,1.3-1.2,1.117-2.118A1.75,1.75,0,0,0,11.75,8.5a.75.75,0,0,1-1.5,0,3.25,3.25,0,0,1,6.435-.649c.368,1.811-.63,2.691-1.6,3.542l-2.106,1.857H16.5a.75.75,0,0,1,0,1.5Z"
                                fill="#6875f5"
                                stroke="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                              ></path>
                              <path
                                d="M19.5,22H2.5a.5.5,0,0,1-.5-.5V4.5a1,1,0,0,0-2,0V22a2,2,0,0,0,2,2H19.5a1,1,0,0,0,0-2Z"
                                fill="#6875f5"
                                stroke="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          Enter the URL of your resource
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          After picking the service requested, you'll need to
                          input the URL of your resource. This can be an
                          instagram post, a TikTok or YouTube video, a webpage
                          or a post on one of the many other social platforms we
                          service.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            viewBox="0 0 140 140"
                            width="140"
                            height="140"
                          >
                            <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                              <path
                                d="M22,0H5.5a2,2,0,0,0-2,2V18.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM17.25,12a2.752,2.752,0,0,1-2.75,2.75h-3a.75.75,0,0,1,0-1.5h3a1.25,1.25,0,0,0,0-2.5H13a.75.75,0,0,1,0-1.5h1.5a1.25,1.25,0,0,0,0-2.5h-3a.75.75,0,0,1,0-1.5h3A2.749,2.749,0,0,1,16.386,10,2.747,2.747,0,0,1,17.25,12Z"
                                fill="#6875f5"
                                stroke="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                              ></path>
                              <path
                                d="M19.5,22H2.5a.5.5,0,0,1-.5-.5V4.5a1,1,0,0,0-2,0V22a2,2,0,0,0,2,2H19.5a1,1,0,0,0,0-2Z"
                                fill="#6875f5"
                                stroke="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          Pay for your order
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          As soon as the payment is processed, your order is
                          good to go and you will get your services in no time!
                          Don't worry, if something goes wrong you are entitled
                          to a full refund. We also have an amazing and
                          passionate support team for any question that might
                          arise.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <div className="bg-white overflow-hidden shadow sm:rounded-lg lg:ml-12 z-20 relative p-6">
                  <OrderWidget />
                </div>
              </div>
            </div>
            <div className="relative mt-12">
              <h4 className="text-4xl leading-8 font-extrabold text-indigo-600 tracking-tight sm:text-4xl sm:leading-9">
                Need help? Have a question?
              </h4>
              <p className="mt-4 mx-auto leading-7 text-gray-500">
                We would love to answer your questions, just email us at: <br />
                <a href="mailto:support@rantic.com">support@rantic.com</a>
              </p>
            </div>
            <div className="relative mt-24">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                We get the snowball rolling
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
                Get started today by trying out our services for yourself, we
                promise you will be satisfied with the results, if not, we even
                offer a 100% money back guarantee.
              </p>
              <div>
                <iframe
                  src="https://player.vimeo.com/video/204320727"
                  style={{
                    width: "100%",
                    height: "50vh",
                    marginTop: "1rem",
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="relative">
        <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Buy Instagram followers & likes with Rantic
        </h3>
        <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
          We help you to build your social fan base without needing your login
          credentials, we do this without using any tactics that will get your
          account banned. Our methods are 100% safe. The followers we bring you
          are THE VERY BEST QUALITY, with followers of their own and plenty of
          posts.
        </p>
        <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
          The Facebook likes are 100% real human likes! Twitter & Instagram
          followers are real followers we offer with fast delivery. We offer
          Youtube views that can land you on the trending page. Our methods &
          Strategies are unique in this industry, and this is why we are the
          leading social media marketing company in this field. We meet you
          where you are and take you to the next level. Get started now by
          selecting the service you desire today.
        </p>
      </div>
    </header>
    <div>
      <div className="max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="px-6 py-6 bg-indigo-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
              Want to get exclusive discounts?
            </h2>
            <p
              className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200"
              id="newsletter-headline"
            >
              Sign up for our newsletter to stay up to date.
            </p>
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <form className="sm:flex" aria-labelledby="newsletter-headline">
              <input
                aria-label="Email address"
                type="email"
                required
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
                  Notify me
                </button>
              </div>
            </form>
            <p className="mt-3 text-sm leading-5 text-indigo-200">
              We care about the protection of your data. Read our&nbsp;
              <a href="#" className="text-white font-medium underline">
                Privacy Policy.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <main></main>
  </Fragment>
);

export default Home;
